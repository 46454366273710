@media only screen and (min-width: 320px) and (max-width: 374px) {
  #navbar {
    height: auto;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }
  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */
  #phone_number {
    font-family: "Gotham-Pro-Medium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    
    margin-top: 7px;
    margin-bottom: 7px;
    margin-right: 0%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "Gotham-Pro-Bold";
    font-size: 12px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .login_popup_modal {
    width: 100%;
    /* margin: 100px 15px !important; */
  }
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 24px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }
  .msger {
    height: 750px !important;
    width: 95% !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    /* height: 46px; */
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "GothamMedium";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px;
    background: #eefaff;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }
  .list_id_form {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sell_listing_box {
    padding: 0 0px !important;
  }
  .drop-menu .select {
    min-width: 79px !important;
    padding: 6px 20px;
  }
  .items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #034a63;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    /*margin-left: 40px;*/
  }
  .items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #ffffff;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #034a63;
    color: #034a63;
    border-radius: 5px;
    cursor: pointer;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 35px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #4d94ac;
  }

  .filter-dropdown select {
    font-family: "Gotham-Pro-Regular" !important;
    font-size: 14px;
    background: none;
    border: 1px solid #878787;
    border-radius: 4px;
    padding: 0px !important;
    color: #878787;
    height: 30px;
    width: 100px;
  }
  .list-item {
    /* border: 1px solid #EEE; */
    background: #fff;
    margin-bottom: 10px;
    padding: 6px !important;
    /* box-shadow: 0px 0px 10px 0px #EEE; */
    border-radius: 18px;
  }
  .tell_us_about_yourself_title {
    font-family: "Gotham-Pro-Bold";
    font-size: 20px !important;
    color: #034a63;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }
  .list-wrapper {
    padding: 0px !important;
    overflow: hidden;
  }
  .lising_table tr th {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    padding: 8px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .lising_table tr td {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    padding: 6px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .edit-save button img {
    height: 9px;
    width: 9px;
    padding: 0;
  }
  .edit-save button {
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
  }
  .filter_by_type_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px;
    padding: 7px !important;
    margin-bottom: 0;
    color: #878787;
  }
  .filter-dropdown {
    display: block !important;
  }
  .listing_number_title {
    font-family: "Gotham-Pro-Regular";
    font-size: 20px !important;
    color: #034a63;
    margin-top: 10px !important;
    padding: 0 0px !important;
    text-transform: uppercase;
  }
  .sell_icon {
    height: 16px;
  }
  .multi_step_form {
    background: #f6f9fb;
    display: block;
    overflow: hidden;
    border-radius: 0 0 0px 0px !important;
    height: 100%;
  }
  /*.multi_step_form #msform .action-button {
		position: fixed;
		bottom: 0px;
      right: 35px;
	}*/
  /*.multi_step_form #msform .previous_button{
		margin-right: 115px!important;
	}
	.multi_step_form {
	    background: #f6f9fb;
	    display: block;
	    overflow: hidden;
	    border-radius: 0 0 22px 22px;
	    height: 91%;
	}*/
  .multi_step_form #msform {
    position: relative;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
    height: 100%;
  }
  .fiberglass_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 18px !important;
    color: #000000;
  }
  .fiberglass_price {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #4d94ac;
    margin-bottom: 5px;
  }
  .listing_id {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #034a63;
    margin-bottom: 5px;
  }
  .Commercial_boat_only_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    margin-bottom: 5px;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
    padding: 8px !important;
    margin-bottom: 0;
  }
  .free_membership_time {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #ef1010;
    background: #fff2f2;
    border: 1px solid #ef1010;
    padding: 7px !important;
    border-radius: 4px;
    margin-right: 0px;
    height: 32px !important;
  }
  .free_membership_nav {
    display: block;
  }
  .free_membership_page_wrapper {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 405px) {
  #navbar {
    height: auto;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }
  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */
  #phone_number {
    font-family: "GothamMedium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 3%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "Gotham-Pro-Bold";
    font-size: 12px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .login_popup_modal {
    width: 100%;
    /* margin: 100px 15px !important; */
  }
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 24px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }

  .msger {
    height: 494px !important;
    width: 95% !important;
    margin-top: 80px !important;
    margin-bottom: 25px !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    height: 46px;
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px !important;
    background: #eefaff;
    border-radius: 4px;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }
  .list_id_form {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sell_listing_box {
    padding: 0 0px !important;
  }
  .drop-menu .select {
    min-width: 79px !important;
    padding: 6px 20px;
  }
  .items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #034a63;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    /*margin-left: 40px;*/
  }
  .items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #ffffff;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #034a63;
    color: #034a63;
    border-radius: 5px;
    cursor: pointer;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 35px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #4d94ac;
  }

  .filter-dropdown select {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px;
    background: none;
    border: 1px solid #878787;
    border-radius: 4px;
    padding: 6px !important;
    color: #878787;
    height: 30px;
    width: 100px;
  }
  .list-item {
    /* border: 1px solid #EEE; */
    background: #fff;
    margin-bottom: 10px;
    padding: 6px !important;
    /* box-shadow: 0px 0px 10px 0px #EEE; */
    border-radius: 18px;
  }
  .tell_us_about_yourself_title {
    font-family: "Gotham-Pro-Bold";
    font-size: 20px !important;
    color: #034a63;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }
  .list-wrapper {
    padding: 0px !important;
    overflow: hidden;
  }
  .lising_table tr th {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    padding: 8px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .lising_table tr td {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    padding: 6px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .edit-save button img {
    height: 9px;
    width: 9px;
    padding: 0;
  }
  .edit-save button {
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
  }
  .filter_by_type_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px;
    padding: 7px !important;
    margin-bottom: 0;
    color: #878787;
  }
  .filter-dropdown {
    display: block !important;
  }
  .listing_number_title {
    font-family: "Gotham-Pro-Regular";
    font-size: 20px !important;
    color: #034a63;
    margin-top: 10px !important;
    padding: 0 0px !important;
    text-transform: uppercase;
  }
  .sell_icon {
    height: 10px;
  }
  .multi_step_form {
    background: #f6f9fb;
    display: block;
    overflow: hidden;
    border-radius: 0 0 0px 0px !important;
    height: 100%;
  }
  /*.multi_step_form #msform .action-button {
		position: fixed;
		bottom: 0px;
      right: 35px;
	}*/
  /*.multi_step_form #msform .previous_button{
		margin-right: 115px!important;
	}
	.multi_step_form {
	    background: #f6f9fb;
	    display: block;
	    overflow: hidden;
	    border-radius: 0 0 22px 22px;
	    height: 91%;
	}*/
  .multi_step_form #msform {
    position: relative;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
    height: 100%;
  }
  .fiberglass_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 18px !important;
    color: #000000;
  }
  .fiberglass_price {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #4d94ac;
    margin-bottom: 5px;
  }
  .listing_id {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #034a63;
    margin-bottom: 5px;
  }
  .Commercial_boat_only_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    margin-bottom: 5px;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
    padding: 8px !important;
    margin-bottom: 0;
  }
  .free_membership_time {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #ef1010;
    background: #fff2f2;
    border: 1px solid #ef1010;
    padding: 7px !important;
    border-radius: 4px;
    margin-right: 0px;
    height: 32px !important;
  }
  .free_membership_nav {
    display: block !important;
  }
  .free_membership_page_wrapper {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 406px) and (max-width: 459px) {
  #navbar {
    height: auto;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }
  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */
  #phone_number {
    font-family: "GothamMedium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 5%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "Gotham-Pro-Bold";
    font-size: 12px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .login_popup_modal {
    width: 100%;
    /* margin: 100px 15px !important; */
  }
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 24px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }
  .msger {
    height: 750px !important;
    width: 95% !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    height: 46px;
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px !important;
    background: #eefaff;
    border-radius: 4px;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    font-family: "Gotham-Pro-Regular";
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }
  .list_id_form {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sell_listing_box {
    padding: 0 0px !important;
  }
  .drop-menu .select {
    min-width: 79px !important;
    padding: 6px 20px;
  }
  .items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #034a63;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    /*margin-left: 40px;*/
  }
  .items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #ffffff;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #034a63;
    color: #034a63;
    border-radius: 5px;
    cursor: pointer;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 35px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #4d94ac;
  }

  .filter-dropdown select {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px;
    background: none;
    border: 1px solid #878787;
    border-radius: 4px;
    padding: 6px !important;
    color: #878787;
    height: 30px;
    width: 100px;
  }
  .list-item {
    /* border: 1px solid #EEE; */
    background: #fff;
    margin-bottom: 10px;
    padding: 6px !important;
    /* box-shadow: 0px 0px 10px 0px #EEE; */
    border-radius: 18px;
  }
  .tell_us_about_yourself_title {
    font-family: "Gotham-Pro-Bold";
    font-size: 20px !important;
    color: #034a63;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }
  .list-wrapper {
    padding: 0px !important;
    overflow: hidden;
  }
  .lising_table tr th {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    padding: 8px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .lising_table tr td {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    padding: 6px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .edit-save button img {
    height: 9px;
    width: 9px;
    padding: 0;
  }
  .edit-save button {
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
  }
  .filter_by_type_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px;
    padding: 7px !important;
    margin-bottom: 0;
    color: #878787;
  }
  .filter-dropdown {
    display: block !important;
  }
  .listing_number_title {
    font-family: "Gotham-Pro-Regular";
    font-size: 20px !important;
    color: #034a63;
    margin-top: 10px !important;
    padding: 0 0px !important;
    text-transform: uppercase;
  }
  .sell_icon {
    height: 10px;
  }
  .multi_step_form {
    background: #f6f9fb;
    display: block;
    overflow: hidden;
    border-radius: 0 0 0px 0px !important;
    height: 100%;
  }
  /*.multi_step_form #msform .action-button {
		position: fixed;
		bottom: 0px;
      right: 35px;
	}*/
  /*.multi_step_form #msform .previous_button{
		margin-right: 115px!important;
	}
	.multi_step_form {
	    background: #f6f9fb;
	    display: block;
	    overflow: hidden;
	    border-radius: 0 0 22px 22px;
	    height: 91%;
	}*/
  .multi_step_form #msform {
    position: relative;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
    height: 100%;
  }
  .fiberglass_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 18px !important;
    color: #000000;
  }
  .fiberglass_price {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #4d94ac;
    margin-bottom: 5px;
  }
  .listing_id {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #034a63;
    margin-bottom: 5px;
  }
  .Commercial_boat_only_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    margin-bottom: 5px;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
    padding: 8px !important;
    margin-bottom: 0;
  }
  .free_membership_time {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #ef1010;
    background: #fff2f2;
    border: 1px solid #ef1010;
    padding: 7px !important;
    border-radius: 4px;
    margin-right: 0px;
    height: 32px !important;
  }
  .free_membership_nav {
    display: block !important;
  }
  .free_membership_page_wrapper {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 460px) and (max-width: 540px) {
  #navbar {
    height: auto;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }
  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */
  #phone_number {
    font-family: "GothamMedium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 7%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "Gotham-Pro-Bold";
    font-size: 12px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .login_popup_modal {
    width: auto;
    /* margin: 100px 15px !important; */
  }
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 24px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }
  .msger {
    height: 750px !important;
    width: 95% !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    height: 46px;
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px !important;
    background: #eefaff;
    border-radius: 4px;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }

  .list_id_form {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sell_listing_box {
    padding: 0 0px !important;
  }
  .drop-menu .select {
    min-width: 79px !important;
    padding: 6px 20px;
  }
  .items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #034a63;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    /*margin-left: 40px;*/
  }
  .items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #ffffff;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #034a63;
    color: #034a63;
    border-radius: 5px;
    cursor: pointer;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 35px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #4d94ac;
  }

  .filter-dropdown select {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px;
    background: none;
    border: 1px solid #878787;
    border-radius: 4px;
    padding: 6px !important;
    color: #878787;
    height: 30px;
    width: 100px;
  }
  .list-item {
    /* border: 1px solid #EEE; */
    background: #fff;
    margin-bottom: 10px;
    padding: 6px !important;
    /* box-shadow: 0px 0px 10px 0px #EEE; */
    border-radius: 18px;
  }
  .tell_us_about_yourself_title {
    font-family: "Gotham-Pro-Bold";
    font-size: 20px !important;
    color: #034a63;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }
  .list-wrapper {
    padding: 0px !important;
    overflow: hidden;
  }
  .lising_table tr th {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    padding: 8px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .lising_table tr td {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    padding: 6px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .edit-save button img {
    height: 9px;
    width: 9px;
    padding: 0;
  }
  .edit-save button {
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
  }
  .filter_by_type_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px;
    padding: 7px !important;
    margin-bottom: 0;
    color: #878787;
  }
  .filter-dropdown {
    display: block !important;
  }
  .listing_number_title {
    font-family: "Gotham-Pro-Regular";
    font-size: 20px !important;
    color: #034a63;
    margin-top: 10px !important;
    padding: 0 0px !important;
    text-transform: uppercase;
  }
  .sell_icon {
    height: 10px;
  }
  .multi_step_form {
    background: #f6f9fb;
    display: block;
    overflow: hidden;
    border-radius: 0 0 0px 0px !important;
    height: 100%;
  }
  /*.multi_step_form #msform .action-button {
		position: fixed;
		bottom: 0px;
      right: 35px;
	}*/
  /*.multi_step_form #msform .previous_button{
		margin-right: 115px!important;
	}
	.multi_step_form {
	    background: #f6f9fb;
	    display: block;
	    overflow: hidden;
	    border-radius: 0 0 22px 22px;
	    height: 91%;
	}*/
  .multi_step_form #msform {
    position: relative;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
    height: 100%;
  }

  .fiberglass_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 18px !important;
    color: #000000;
  }
  .fiberglass_price {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #4d94ac;
    margin-bottom: 5px;
  }
  .listing_id {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #034a63;
    margin-bottom: 5px;
  }
  .Commercial_boat_only_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    margin-bottom: 5px;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
    padding: 8px !important;
    margin-bottom: 0;
  }
  .free_membership_time {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #ef1010;
    background: #fff2f2;
    border: 1px solid #ef1010;
    padding: 7px !important;
    border-radius: 4px;
    margin-right: 0px;
    height: 32px !important;
  }
  .free_membership_nav {
    display: block !important;
  }
  .free_membership_page_wrapper {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 541px) and (max-width: 767px) {
  #navbar {
    height: auto;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }
  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */
  #phone_number {
    font-family: "GothamMedium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 7%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "GothamMedium";
    font-size: 14px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .login_popup_modal {
    width: auto;
    /* margin: 100px 15px !important; */
  }
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 24px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }
  .msger {
    height: 750px !important;
    width: 95% !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    height: 46px;
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px !important;
    background: #eefaff;
    border-radius: 4px;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }
  .list_id_form {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sell_listing_box {
    padding: 0 0px !important;
  }
  .drop-menu .select {
    min-width: 79px !important;
    padding: 6px 20px;
  }
  .items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #034a63;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    /*margin-left: 40px;*/
  }
  .items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    font-weight: 500;
    background: #ffffff;
    height: 40px;
    width: auto !important;
    padding: 10px;
    border: 1px solid #034a63;
    color: #034a63;
    border-radius: 5px;
    cursor: pointer;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 35px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #4d94ac;
  }

  .filter-dropdown select {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px;
    background: none;
    border: 1px solid #878787;
    border-radius: 4px;
    padding: 6px !important;
    color: #878787;
    height: 30px;
    width: 100px;
  }
  .list-item {
    /* border: 1px solid #EEE; */
    background: #fff;
    margin-bottom: 10px;
    padding: 6px !important;
    /* box-shadow: 0px 0px 10px 0px #EEE; */
    border-radius: 18px;
  }
  .tell_us_about_yourself_title {
    font-family: "Gotham-Pro-Bold";
    font-size: 20px !important;
    color: #034a63;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }
  .list-wrapper {
    padding: 0px !important;
    overflow: hidden;
  }
  .lising_table tr th {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    padding: 8px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .lising_table tr td {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    padding: 6px !important;
    border: 1px solid #ababab;
    text-align: center;
  }
  .edit-save button img {
    height: 9px;
    width: 9px;
    padding: 0;
  }
  .edit-save button {
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
  }
  .filter_by_type_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px;
    padding: 7px !important;
    margin-bottom: 0;
    color: #878787;
  }
  .filter-dropdown {
    display: block !important;
  }
  .listing_number_title {
    font-family: "Gotham-Pro-Regular";
    font-size: 20px !important;
    color: #034a63;
    margin-top: 10px !important;
    padding: 0 0px !important;
    text-transform: uppercase;
  }
  .sell_icon {
    height: 10px;
  }
  .multi_step_form {
    background: #f6f9fb;
    display: block;
    overflow: hidden;
    border-radius: 0 0 0px 0px !important;
    height: 100%;
  }
  /*.multi_step_form #msform .action-button {
		position: fixed;
		bottom: 0px;
      right: 35px;
	}*/
  /*.multi_step_form #msform .previous_button{
		margin-right: 115px!important;
	}
	.multi_step_form {
	    background: #f6f9fb;
	    display: block;
	    overflow: hidden;
	    border-radius: 0 0 22px 22px;
	    height: 91%;
	}*/
  .multi_step_form #msform {
    position: relative;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
    height: 100%;
  }
  .fiberglass_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 18px !important;
    color: #000000;
  }
  .fiberglass_price {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #4d94ac;
    margin-bottom: 5px;
  }
  .listing_id {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #034a63;
    margin-bottom: 5px;
  }
  .Commercial_boat_only_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    margin-bottom: 5px;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
    padding: 8px !important;
    margin-bottom: 0;
  }

  .free_membership_time {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #ef1010;
    background: #fff2f2;
    border: 1px solid #ef1010;
    padding: 7px !important;
    border-radius: 4px;
    margin-right: 0px;
    height: 32px !important;
  }
  .free_membership_nav {
    display: block !important;
  }
  .free_membership_page_wrapper {
    margin-top: 125px !important;
  }
}
/*-----------------------------------------------Mobile Media Query End---------------------------------------------------------------*/
/*-----------------------------------------------Desktop and Laptop Media Query Start-------------------------------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 870px) {
  #navbar {
    height: auto;
  }
  select.ct_language_select {
    font-size: 10px !important;
    padding-inline: 2px !important;
    width: fit-content;
  }
  #hero-area {
    background-image: none;
    height: auto;
  }

  /* .banner {
    background-color: #034a63;
    padding-bottom: 70px;
  } */

  #phone_number {
    font-family: "GothamMedium";
    font-size: 10px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
  }
  /* #nav-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 7%;
  } */
  .dropdown-wrapper {
    width: auto;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .dropdown-wrapper span {
    font-family: "GothamMedium";
    font-size: 9px;
    color: #ffffff;
  }
  .word_icon {
    height: 10px;
  }
  .login_btn {
    font-size: 10px;
    font-weight: 500;
    font-family: "Gotham-Pro-Regular";
    background-color: #004964;
    color: #fff;
    height: 30px;
    /* width: 183px; */
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 6px 10px;
  }
  #hero-name {
    padding-top: 130px;
  }
  .logo-img {
    width: 165px;
  }
  #hero-blurb {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }
  .explore_items_for_sale_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #024861;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .explore_items_wanted_btn {
    font-family: "Gotham-Pro-Regular";
    background-color: #4b91a9;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .commission_free_box {
    background-color: #e4f8ff;
    border: 1px solid #034a63;
    /* opacity: 0.6; */
    border-radius: 10px;
    padding: 30px 20px;
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .fsbo_text {
    font-family: "GothamMedium";
    font-size: 14px;
    font-weight: 600;
    color: #034a63;
    text-align: center;
  }
  .click_here_to_sell_now_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 11px;
    font-weight: 500;
    color: #4b91a9;
    margin: 10px auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  /*.login_popup_modal {
	    width: auto;
	}*/
  .login_popup_logo {
    height: 89px;
  }
  .join_novi_marin_brokers_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #043e56;
  }
  .banner-heading-underline {
    width: 230px;
  }
  .signup_and_experience_para {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 600;
    color: #66a9c2;
    text-align: center;
  }
  .we_will_send_para {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 10px !important;
    color: gray;
    text-align: center;
    padding-top: 0px;
  }
  .form-group .form-control {
    font-family: "Gotham-Pro-Medium";
    font-weight: 500;
    font-size: 8px;
    color: gray;
  }
  .get_otp_btn {
    font-family: "Gotham-Pro-Regular";
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
    background-color: #4c93ab;
    height: 29px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    /* position: fixed; */
    bottom: 0;
  }
  /*----------sign up----------*/
  .logo_2 {
    width: 99px !important;
  }
  .help_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    height: 43px !important;
    width: 60px !important;
    padding: 5px !important;
    cursor: pointer;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    color: #878787;
    padding: 8px;
    margin-bottom: 0;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 28px !important;
    padding-top: 30px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    color: #4d94ac;
  }
  .icon_map {
    background: white;
    padding: 10px;
    border: 1px solid #4d94ac;
    border-radius: 4px;
    float: right;
    /* margin-bottom: 20px; */
    margin-top: 35px !important;
    cursor: pointer;
  }
  .mini-photo {
    height: 24px !important;
    width: 24px !important;
  }
  .header-dropdown {
    padding: 0px 10px !important;
  }
  .msger-header {
    font-family: "Gotham-Pro-Bold";
    font-size: 14px !important;
    font-weight: 600;
    height: 46px;
    padding: 14px !important;
    border-bottom: var(--border);
    background: #4d94ac;
    color: #ffffff;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .msg-img {
    width: 28px !important;
    height: 28px !important;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 20px !important;
  }
  .msger-inputarea {
    margin-top: 100px;
    padding: 15px 30px !important;
  }
  .msger-input {
    font-family: "Gotham-Pro-Regular";
    padding: 8px 15px !important;
    flex: 1;
    background: #f9f9f9;
    border: 1px solid #034a63;
    font-size: 10px !important;
  }
  .msger-inputarea * {
    padding: 5px !important;
    border: none;
    border-radius: 4px !important;
    font-size: 14px;
  }
  .left-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    color: #29a6d4 !important;
    padding: 12px 10px !important;
    background: #eefaff;
    border-radius: 4px;
  }
  .right-msg .msg-bubble {
    font-family: "Gotham-Pro-Regular";
    font-size: 10px !important;
    background: var(--right-msg-bg);
    color: #fff;
    padding: 12px 10px !important;
    background: #034a63;
    border-radius: 4px;
  }
  .user-menu-wrap {
    position: relative;
    width: 70px;
    margin-left: 10px;
    /* margin: 50px auto; */
  }
  .msg-text span {
    color: #034a63;
  }
  .msg-bubble_2 {
    background: #29a6d4 !important;
  }
  .msg-bubble_2 .msg-text {
    color: #ffffff !important;
  }
}
@media only screen and (min-width: 871px) and (max-width: 991px) {
  #phone_number {
    font-family: "Gotham-Pro-Medium";
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
  }
  video {
    width: auto;
    height: auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    color: #878787;
    padding: 8px;
    margin-bottom: 0;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 28px !important;
    padding-top: 30px !important;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    color: #4d94ac;
  }
  .icon_map {
    background: white;
    padding: 10px;
    border: 1px solid #4d94ac;
    border-radius: 4px;
    float: right;
    /* margin-bottom: 20px; */
    margin-top: 32px !important;
    cursor: pointer;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #phone_number {
    font-family: "Gotham-Pro-Medium";
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
  }
  .fsbo_text {
    font-family: "GothamMedium";
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
  }
  video {
    width: auto;
    height: auto;
  }
  .brand_new_service_btn {
    background-color: #f7721f;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .traditional_service_btn {
    background-color: #28a2cf;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .view_all_service_btn {
    background-color: #034a63;
    font-family: "Gotham-Pro-Regular";
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px 15px 8px 25px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
  }
  .free_membership_ends_in_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 15px !important;
    color: #878787;
    padding: 8px;
    margin-bottom: 0;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 20px !important;
    padding-top: 20px;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 12px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    color: #4d94ac;
  }
  .filter_applied_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 9px !important;
    color: #878787;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1359px) {
  video {
    width: auto;
    height: auto;
  }
  .items_for_sale_text {
    font-family: "Gotham-Pro-Bold";
    font-weight: 500;
    font-size: 18px !important;
    padding-top: 20px;
    color: #034a63;
  }
  .your_searched_results_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 13px !important;
    color: #878787;
  }
  .items_text {
    font-family: "Gotham-Pro-Regular";
    font-size: 14px !important;
    color: #4d94ac;
  }
}
@media only screen and (min-width: 1360px) and (max-width: 1440px) {
  video {
    width: auto;
    height: auto;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  video {
    width: auto;
    height: auto;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1919px) {
}
@media only screen and (min-width: 1920px) and (max-width: 2020px) {
}
@media only screen and (min-width: 2021px) and (max-width: 2100px) {
}
@media only screen and (min-width: 2101px) and (max-width: 2200px) {
}
@media only screen and (min-width: 2201px) and (max-width: 2300px) {
}
@media only screen and (min-width: 2301px) and (max-width: 2400px) {
}
@media only screen and (min-width: 2401px) and (max-width: 2560px) {
}
@media only screen and (min-width: 2561px) and (max-width: 2660px) {
}
@media only screen and (min-width: 2661px) and (max-width: 2800px) {
}
/*-------------------------------remaining---------------------------------------*/
@media only screen and (min-width: 2801px) and (max-width: 3000px) {
}
@media only screen and (min-width: 3001px) and (max-width: 3300px) {
}
@media only screen and (min-width: 3301px) and (max-width: 3500px) {
}
@media only screen and (min-width: 3501px) and (max-width: 3700px) {
}
@media only screen and (min-width: 3701px) and (max-width: 3900px) {
}
@media only screen and (min-width: 3901px) and (max-width: 4100px) {
}
@media only screen and (min-width: 4101px) and (max-width: 4200px) {
}
@media only screen and (min-width: 4201px) and (max-width: 4300px) {
}
@media only screen and (min-width: 4301px) and (max-width: 4400px) {
}

/*--------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 4401px) and (max-width: 4500px) {
}
@media only screen and (min-width: 4501px) and (max-width: 4600px) {
}
@media only screen and (min-width: 4601px) and (max-width: 4700px) {
}
@media only screen and (min-width: 4701px) and (max-width: 4800px) {
}
@media only screen and (min-width: 4801px) and (max-width: 4900px) {
}
@media only screen and (min-width: 4901px) and (max-width: 5000px) {
}
@media only screen and (min-width: 5001px) and (max-width: 5400px) {
}
@media only screen and (min-width: 5401px) and (max-width: 5600px) {
}
@media only screen and (min-width: 5601px) and (max-width: 5800px) {
}
@media only screen and (min-width: 5801px) and (max-width: 6000px) {
}
@media only screen and (min-width: 6001px) and (max-width: 6200px) {
}
@media only screen and (min-width: 6201px) and (max-width: 6400px) {
}
@media only screen and (min-width: 6401px) and (max-width: 6600px) {
}
@media only screen and (min-width: 6601px) and (max-width: 6800px) {
}
@media only screen and (min-width: 6801px) and (max-width: 7000px) {
}
@media only screen and (min-width: 7001px) and (max-width: 7200px) {
}
@media only screen and (min-width: 7201px) and (max-width: 7400px) {
}
@media only screen and (min-width: 7401px) and (max-width: 7600px) {
}
@media only screen and (min-width: 7601px) and (max-width: 7800px) {
}
/*@media only screen and (min-width: 7801px)  and (max-width:8000px)
{
}
@media only screen and (min-width: 8001px)  and (max-width:8200px)
{
}
@media only screen and (min-width: 8201px)  and (max-width:8400px)
{
}
@media only screen and (min-width: 8401px)  and (max-width:8600px)
{
}
@media only screen and (min-width: 8601px)  and (max-width:8800px)
{
}
@media only screen and (min-width: 8801px)  and (max-width:9000px)
{
}*/
/*-------------------------------------------Mobile Media Query Start-----------------------------------------------------------------*/

/*------------------------------------------------Desktop and Laptop Media Query End-------------------------------------------------------------*/

@media screen and (max-width: 1280px) {
  /* #map-container {
height: 592px !important;
} */
  .left-sidebar {
    height: 618px;
  }
  .infinite-scroll-section {
    max-height: 515px;
  }
  .page-wrapper .page-content {
    height: 96vh;
  }
  .sidebar-wrapper {
    background-color: #fff !important;
  }
}
@media screen and (max-width: 1024px) {
  #map-container {
    height: 375px !important;
    min-height: calc(100vh - 208px);
  }
  .page-wrapper.toggled .sidebar-wrapper {
    height: calc(100vh - 150px);
  }

  .left-sidebar {
    height: 645px;
  }
  .ct_information_search {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .ct_pad_15 {
    padding-top: 15px;
  }
  .ct_navbar_main{
    flex-direction: column  ;
  }
  .ct_mt_0 {
    margin-top: 0px !important;
  }
  .page-wrapper .ct_dashboard_fixed_page,
  .ct_dashboard_fixed_page {
    height: auto !important;
  }
  #video-container {
    height: calc(100vh + 616px) !important;
  }
  .ct_custom_service_modal div#video-container {
    height: 350px !important;
  }
}
/* @media screen and (min-device-width: 768px) and (max-device-width: 853px) { 

#video-container {
  height: calc(100vh + 0px) !important;
}
} */
@media screen and (min-device-width: 768px) and (max-device-width: 853px) {
  /* #video-container {
    height: calc(100vh + -4px) !important;
}
.banner,#hero-area   {
  height: 100% !important;
} */
}

@media screen and (max-width: 870px) {
  #video-container {
    height: calc(100vh + 0px) !important;
  }
  footer.ct_footer_white .footer_bottom nav.bg-light a.nav-link {
    color: #000 !important;
  }
  .react-tel-input .form-control {
    text-indent: 46px;
  }
}
@media screen and (max-width: 767px) {
  .logo-img {
    width: 200px !important;
  }
  .ct_mobile_help_btn.help_btn {
    display: block !important;
    width: 100% !important;
    height: 43px !important;
    margin-top: 15px;
    font-size: 16px !important;
}

  select.ct_language_select {
    display: none !important;
  }
  .page-wrapper.ct_mt_94_dash {
    margin-top: 218px !important;
}
  .ct_overflow_unset_responsive {
    overflow-y: unset;
  }
  .ct_infinite_scroll_hide_on_responsive
    .infinite-scroll-component__outerdiv
    .infinite-scroll-component {
    overflow-y: unset !important;
    height: 100% !important;
    max-height: 100%;
  }
  #hero-blurb {
    font-size: 24px !important;
  }
  section.section.ct_billing_hidtory_pt_top {
    padding-top: 250px !important;
}
  .ct_pad_15 {
    padding: 15px 15px 0px;
  }
  .ct_short_option_map_on_mobile {
    display: block !important;
  }

  .ct_mobile_place_change {
    display: flex;
    flex-direction: column-reverse;
  }
  div#video-container video {
    display: none;
  }
  div#video-container:before {
    content: "";
    position: absolute;
    background: #034a63;
    width: 100%;
    height: 100%;
  }
  .ct_collapse_map_on_mobilep {
    position: relative;
    right: 0;
    transition: 0.4s ease all;
    z-index: 99;
    height: 0px;
    overflow-y: hidden;
    visibility: hidden;
    transition: 0.4s ease all;
    display: none;
  }
  .ct_section_top_180_mob {
    top: 250px !important;
  }
  .package_section {
    top: 150px !important;
  }
  .ct_collapse_map_on_mobilep.ct_map_show_click {
    height: 520px;
    visibility: visible;
    display: block;
  }
  .ct_mob_767_icon_none {
    display: none;
  }
  .infinite-scroll-section {
    height: 100%;
    overflow-y: unset !important;
    max-height: 100%;
  }
  
}
@media screen and (min-device-width: 600px) and (max-device-width: 767px) {
  #video-container {
    height: calc(100vh + 0px) !important;
  }
}
@media screen and (min-device-width: 391px) and (max-device-width: 430px) {
  .page-header button {
    width: 84px;
  }
  div#video-container:before {
    height: 60%;
  }
  button#Sell {
    margin-left: 25px;
}
}
@media screen and (min-device-width: 376px) and (max-device-width: 390px) {
  div#video-container:before {
    height: 65%;
  }
  button#Sell {
    margin-left: 25px;
}
}

@media screen and (min-device-width: 360px) and (max-device-width: 375px) {
  div#video-container:before {
    height: 85%;
  }
  button#Sell {
    margin-left: 25px;
}
}
@media screen and (max-width: 594px) {
  #navbar {
    height: auto !important;
  }
  .ct_left_nav_bar {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    width: 100%;
  }
  div#nav-hamburger {
    justify-content: center;
    width: 100%;
  }

  .goog-te-gadget-simple,
  .login_btn {
    font-size: 12px !important;
  }
  .sell_icon {
    display: none;
  }
  .page-wrapper .page-content > div {
    padding: 0px 15px !important;
  }
  .banner,
  #hero-area {
    height: auto !important;
  }

  .ct_px_res_8{
    padding-inline: 8px !important;
  }
}

@media screen and (min-width: 1500px) {
  .ct_vertical_h_on_big_screen,
  .ct_dash_scroll_big_screen {
    height: 100vh !important;
  }

  .ct_vertical_h_on_big_screen,
  .ct_dash_scroll_big_screen {
    height: 62vh !important;
  }
}
